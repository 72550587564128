 
// import Cookies from 'js-cookie'

// const DISABLE_LOGS = true

// declare global {
// 	interface Window {
// 		gtag
// 		dataLayer
// 	}
// }

export const init = () => {
	// cart event
	document.addEventListener('liquid-ajax-cart:request-end', event => {
		const { requestState, cart, previousCart, sections } = event.detail

		window.Shopify.analytics.publish('cart_updated', {
			action: requestState.requestType,
			cart: {
				...cart,
				sections: undefined
			}
		})
	})
}

// interface CookieFirstConsent {
// 	necessary: boolean
// 	functional: boolean
// 	performance: boolean
// 	advertising: boolean
// }

// export const DEFAULT_CONSENT: CookieFirstConsent = {
// 	necessary: true,
// 	functional: false,
// 	performance: false,
// 	advertising: false
// }

// export function initConsent(preconsent: CookieFirstConsent) {
// 	// retrieve saved configuration from cookies
// 	const savedConsent = Cookies.get('cookiefirst-consent')
// 	const consent: CookieFirstConsent = savedConsent ? JSON.parse(savedConsent) : preconsent

// 	if (window.gtag) window.gtag('set', 'ads_data_redaction', consent.advertising)

// 	return { consent: updateConsent(consent, { state: 'default', save: false }), restored: Boolean(savedConsent) }
// }

// export function updateConsent(consent: CookieFirstConsent, options = { state: 'update', save: true }) {
// 	const denied = 'denied'
// 	const granted = 'granted'

// 	// map to GTM consent mode model
// 	const gtmConsent = {
// 		security_storage: granted,
// 		analytics_storage: consent.performance ? granted : denied,
// 		functionality_storage: consent.functional ? granted : denied,
// 		ad_storage: consent.advertising ? granted : denied,
// 		ad_user_data: consent.advertising ? granted : denied,
// 		ad_personalization: consent.advertising ? granted : denied,
// 		personalization_storage: consent.functional ? granted : denied
// 	}

// 	if (window.gtag) {
// 		window.gtag('consent', options.state, gtmConsent)
// 	}

// 	const keys = ['necessary', 'functional', 'performance', 'advertising']
// 	for (const key in consent) {
// 		if (keys.includes(key)) event(`cf_consent_${key}`, { [`cf_${key}_enabled`]: consent[key] })
// 	}

// 	if (options.save) {
// 		Cookies.set('cookiefirst-consent', JSON.stringify({ ...consent, timestamp: Date.now() }))
// 	}

// 	return consent
// }

// export function acceptAllConsent() {
// 	updateConsent({
// 		necessary: true,
// 		functional: true,
// 		performance: true,
// 		advertising: true
// 	})
// }
