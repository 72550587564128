export interface Variant {
	id: string
	title: string
	price: number
	available: boolean
}

export interface ProductFormProps {
	variants: Variant[]
	defaultVariantId: string
}

export default {
	name: 'productForm',
	component({ variants, defaultVariantId }: ProductFormProps) {
		return {
			quantity: 1,
			selectedVariantId: defaultVariantId || variants[0].id,
			variants,
			isProductPickerOpen: false,
			isLoading: false,

			convertCentsToDollars(price: number) {
				return price / 100
			},

			formatPrice(price: number) {
				return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
			},

			get selectedVariant() {
				return this.variants.find(variant => variant.id === this.selectedVariantId)
			},

			get unitPrice() {
				return this.selectedVariant ? this.convertCentsToDollars(this.selectedVariant.price / (this.selectedBottlesPerUnit || 6)) : 0
			},

			get totalPrice() {
				return this.selectedVariant ? this.convertCentsToDollars(this.selectedVariant.price * this.quantity) : 0
			},

			get formattedUnitPrice() {
				return this.formatPrice(this.unitPrice)
			},

			get formattedTotalPrice() {
				return this.formatPrice(this.totalPrice)
			},

			selectVariant(variantId: string) {
				this.selectedVariantId = variantId;
				this.closeProductPicker();
			},

			openProductPicker() {
				this.isProductPickerOpen = true
			},

			closeProductPicker() {
				this.isProductPickerOpen = false;
			},

			toggleProductPicker() {
				this.isProductPickerOpen = !this.isProductPickerOpen;
			},

			incrementQuantity() {
				this.quantity++
			},

			decrementQuantity() {
				if (this.quantity > 1) {
					this.quantity--
				}
			},

			setQuantity(value: number) {
				if (value >= 1) {
					this.quantity = value
				}
			},

			addToCart() {
				this.isLoading = true
				const body = {
					items: [
						{
							id: this.selectedVariantId,
							quantity: this.quantity
						}
					]
				}

				const options = {
					lastCallback: () => {
						this.isLoading = false
					}
				}

				window.liquidAjaxCart.add(body, options)
			},

			init() {
				if (!this.selectedVariant) {
					this.selectedVariantId = this.variants[0].id
				}
			}
		}
	}
}
