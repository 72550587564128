export default {
	name: 'cocktailsFilters',
	component(initialText: string) {
		return {
			isOpen: false,
			selectedProduct: 'all',
			selectedProductTitle: initialText,

			handleClick(e: Event) {
				e.stopPropagation()
				this.toggleDropdown()
			},
			handleOutsideClick(e: MouseEvent) {
				const list = document.querySelector('.variant-selector-list') as HTMLElement
				if (list && !list.contains(e.target as Node)) {
					this.closeDropdown()
				}
			},
			handleFilter(e: Event) {
				e.stopPropagation()
				const button = e.target as HTMLButtonElement
				this.selectedProduct = button.dataset.product || 'all'
				this.selectedProductTitle = this.selectedProduct === 'all' ? initialText : button.textContent || initialText
				this.filterRecipes()
				this.closeDropdown()
			},
			toggleDropdown() {
				this.isOpen = !this.isOpen
				document.body.classList.toggle('selector-open')
				const list = document.querySelector('.variant-selector-list')
				list?.setAttribute('aria-expanded', this.isOpen.toString())
			},
			closeDropdown() {
				this.isOpen = false
				document.body.classList.remove('selector-open')
				const list = document.querySelector('.variant-selector-list')
				list?.setAttribute('aria-expanded', 'false')
			},
			filterRecipes() {
				const recipeCards = document.querySelectorAll('.recipe-card')
				recipeCards.forEach((card: Element) => {
					if (card instanceof HTMLElement) {
						const cardProducts = card.dataset.products?.split(',') || []
						if (this.selectedProduct === 'all' || cardProducts.includes(this.selectedProduct)) {
							card.style.display = ''
						} else {
							card.style.display = 'none'
						}
					}
				})
			},
			init() {
				document.addEventListener('click', this.handleOutsideClick.bind(this))
			},
			destroy() {
				document.removeEventListener('click', this.handleOutsideClick.bind(this))
			}
		}
	}
}
