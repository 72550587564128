export default {
	name: 'sliceDiptychLinks',
	component() {
		return {
			index: 0,
			prevIndex: 0,
			images: [],

			init() {
				this.images = [...this.$el.querySelectorAll('.diptych-image')]
				this.images[this.index].classList.add('active')

				this.$watch('index', newValue => {
					this.images.forEach((image, i) => {
						if (i === newValue) {
							image.classList.add('active')
							image.style.zIndex = 2
						} else if (i === this.prevIndex) {
							image.classList.remove('active')
							image.style.zIndex = 1
						} else {
							image.style.zIndex = 0
						}
					})
				})
			},

			handleMouseOver(e: MouseEvent) {
				this.prevIndex = this.index
				const target = e.currentTarget as HTMLElement
				const index = target.getAttribute('data-index')
				this.index = parseInt(index, 10)
				console.log(this.index)
			}
		}
	}
}
