export default {
	name: 'variantSelector',
	component() {
		return {
			isOpen: false,

			handleClick(e: Event) {
				e.stopPropagation()
				this.isOpen = !this.isOpen
				document.body.classList.toggle('selector-open')
				const list = document.querySelector('.variant-selector-list')
				list?.setAttribute('aria-expanded', this.isOpen.toString())
			},
			handleOutsideClick(e: MouseEvent) {
				const list = document.querySelector('.variant-selector-list') as HTMLElement
				if (list && !list.contains(e.target as Node)) {
					this.isOpen = false
					list.setAttribute('aria-expanded', 'false')
					document.body.classList.remove('selector-open')
				}
			},
			init() {
				document.addEventListener('click', this.handleOutsideClick.bind(this))
				const list = document.querySelector('.variant-selector-list')
				list?.addEventListener('click', this.handleClick.bind(this))
			},
			destroy() {
				document.removeEventListener('click', this.handleOutsideClick.bind(this))
				const list = document.querySelector('.variant-selector-list')
				list?.removeEventListener('click', this.handleClick.bind(this))
			}
		}
	}
}
