import Lenis from 'lenis'

const lenis = new Lenis()
window._lenis = lenis

window.addEventListener(
	'load',
	() => {
		const chatBox = document.getElementById('shopify-chat')
		chatBox?.setAttribute('data-lenis-prevent', 'true')
	},
	{ once: true }
)

const raf = time => {
	lenis.raf(time)
	requestAnimationFrame(raf)
}
requestAnimationFrame(raf)
